<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <CompanyDetailsGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab title="Empreendimento">
          <CompanyDetailsEnterprise :items="itemData.projects.filter(arr => arr.isActive) || []" />
        </b-tab>
        <b-tab title="Contatos">
          <CompanyDetailsContacts
            relation="company_id"
            :items="contactsCompany"
          />
        </b-tab>
        <b-tab>
          <template #title>
            Anexos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ attachmentsCompany.length }}
            </b-badge>
          </template>
          <CompanyDetailsAttachments
            :items="attachmentsCompany"
            relation="company_id"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import CompanyDetailsGeneralData from './CompanyDetailsGeneralData.vue'
import CompanyDetailsEnterprise from './CompanyDetailsEnterprise.vue'
import CompanyDetailsContacts from '@/components/contacts-details/DetailsContacts.vue'
import CompanyDetailsAttachments from '@/components/attachments-details/DetailsAttachments.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,

    CompanyDetailsGeneralData,
    CompanyDetailsEnterprise,
    CompanyDetailsContacts,
    CompanyDetailsAttachments,
  },
  computed: {
    ...mapGetters({ itemData: 'company/getCompanyData', contacts: 'contacts/getContacts', attachments: 'upload/getUpload' }),
    attachmentsCompany() {
      return this.attachments.filter(arr => arr.company_id === Number(this.$route.params.id))
    },
    contactsCompany() {
      return this.contacts.filter(arr => arr.company_id === Number(this.$route.params.id))
    },
  },
  created() {
    if (!this.verifyRouteAddNewItem()) {
      this.fetchCompanyById(this.$route.params.id)
      this.fetchContacts()
      this.fetchUploads()
    }
  },
  methods: {
    ...mapActions({
      fetchCompanyById: 'company/fetchCompanyById', fetchContacts: 'contacts/fetchContacts', fetchUploads: 'upload/fetchUploads',
    }),
  },
}
</script>
