<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <EnterpriseDetailsGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab title="Licenciamentos">
          <EnterpriseDetailsProcess
            :process="true"
            :items="licensing"
          />
        </b-tab>
        <b-tab title="Licenças">
          <EnterpriseDetailsLicense
            :license="true"
            :items="license"
          />
        </b-tab>
        <b-tab title="Contatos">
          <EnterpriseDetailsContacts
            relation="project_id"
            :items="contactsEnterprise"
          />
        </b-tab>
        <b-tab>
          <template #title>
            Anexos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ attachmentsEnterprise.length }}
            </b-badge>
          </template>
          <EnterpriseDetailsAttachments
            :items="attachmentsEnterprise"
            relation="project_id"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import EnterpriseDetailsGeneralData from './EnterpriseDetailsGeneralData.vue'
import EnterpriseDetailsProcess from './EnterpriseDetailsProcess.vue'
import EnterpriseDetailsLicense from './EnterpriseDetailsLicense.vue'
import EnterpriseDetailsContacts from '@/components/contacts-details/DetailsContacts.vue'
import EnterpriseDetailsAttachments from '@/components/attachments-details/DetailsAttachments.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,

    EnterpriseDetailsGeneralData,
    EnterpriseDetailsProcess,
    EnterpriseDetailsLicense,
    EnterpriseDetailsContacts,
    EnterpriseDetailsAttachments,
  },
  computed: {
    ...mapGetters({
      itemData: 'enterprise/getEnterpriseData',
      contacts: 'contacts/getContacts',
      licensing: 'licensing/getLicensing',
      license: 'licensingConditions/getLicensingConditions',
      attachments: 'upload/getUpload',
    }),
    attachmentsEnterprise() {
      return this.attachments.filter(arr => arr.project_id === Number(this.$route.params.id))
    },
    contactsEnterprise() {
      return this.contacts.filter(arr => arr.project_id === Number(this.$route.params.id))
    },
  },
  created() {
    if (!this.verifyRouteAddNewItem()) {
      this.fetchEnterpriseById(this.$route.params.id)
      this.fetchContacts()
      this.fetchLicensing()
      this.fetchLicensingConditions()
      this.fetchUploads()
    }
  },
  methods: {
    ...mapActions({
      fetchEnterpriseById: 'enterprise/fetchEnterpriseById',
      fetchContacts: 'contacts/fetchContacts',
      fetchLicensing: 'licensing/fetchLicensing',
      fetchLicensingConditions: 'licensingConditions/fetchLicensingConditions',
      fetchUploads: 'upload/fetchUploads',
    }),
  },
}
</script>
