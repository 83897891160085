<template>
  <div>
    <div
      v-if="$can('update', 'project')"
      class="btn-actions-content d-flex"
    >
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="cancel()"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges(enterpriseData)"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <div v-if="!apiInCall">
      <h6>Dados da empresa</h6>
      <validation-observer ref="form">
        <b-form class="mt-3">
          <b-row>
            <b-col>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Pessoa Jurídica"
                :disabled="!edit"
              >
                <b-form-radio-group
                  v-model="enterpriseData.isLegalPerson"
                  :options="optionsLegalPerson"
                  :aria-describedby="ariaDescribedby"
                  name="radio-inline"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="enterpriseData.isLegalPerson"
            >
              <validation-provider
                v-slot="{ errors }"
                name="CNPJ"
                rules="required|cnpj"
              >
                <b-form-group label="CNPJ">
                  <b-input-group>
                    <b-form-input
                      id="cnpj"
                      v-model="enterpriseData.cnpj"
                      v-mask="'##.###.###/####-##'"
                      :disabled="!edit"
                      type="text"
                      @blur="getDataCompanyByCnpj(enterpriseData.cnpj)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-else
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="CPF"
                rules="required|cpf"
              >
                <b-form-group label="CPF">
                  <b-input-group>
                    <b-form-input
                      id="cpf"
                      v-model="enterpriseData.cpf"
                      v-mask="'###.###.###-##'"
                      :disabled="!edit"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="enterpriseData.isLegalPerson"
              md="3"
              class=""
            >
              <validation-provider
                v-slot="{ errors }"
                name="nome fantasia"
                rules="required|max:100"
              >
                <b-form-group label="Nome fantasia">
                  <b-input-group>
                    <b-form-input
                      id="nome-fantasia"
                      v-model="enterpriseData.fantasy_name"
                      :disabled="!edit"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-else
              md="3"
            >
              <validation-provider
                v-slot="{ errors }"
                name="nome completo"
                rules="required|alpha_spaces|max:100"
              >
                <b-form-group label="Nome Completo">
                  <b-input-group>
                    <b-form-input
                      id="nome-completo"
                      v-model="enterpriseData.full_name"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="!enterpriseData.isLegalPerson"
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="documento de identificação"
                rules="max:100"
              >
                <b-form-group label="Documento de Identificação">
                  <b-input-group>
                    <b-form-input
                      id="documento-identificacao"
                      v-model="enterpriseData.identification_document"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="!enterpriseData.isLegalPerson"
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="órgão emissor"
                rules="max:100"
              >
                <b-form-group label="Órgão Emissor">
                  <b-input-group>
                    <b-form-input
                      id="orgao-emissor"
                      v-model="enterpriseData.document_issuer"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="!enterpriseData.isLegalPerson"
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="setor"
                rules="required"
              >
                <b-form-group label="Setor">
                  <b-form-select
                    id="setor"
                    v-model="enterpriseData.sector.id"
                    :options="sectorOptions"
                    :disabled="!edit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="!enterpriseData.isLegalPerson"
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="empresa"
                rules="required"
              >
                <b-form-group label="Empresa Vinculada">
                  <b-input-group>
                    <b-form-select
                      id="empresa-vinculada"
                      v-model="enterpriseData.company.id"
                      :options="companiesOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="enterpriseData.isLegalPerson"
            >
              <validation-provider
                v-slot="{ errors }"
                name="razão social"
                rules="required|max:100"
              >
                <b-form-group label="Razão Social">
                  <b-input-group>
                    <b-form-input
                      id="razao-social"
                      v-model="enterpriseData.project_name"
                      :disabled="!edit"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="enterpriseData.isLegalPerson"
              md="2"
            >
              <b-form-group label="CNAE Principal">
                <b-input-group>
                  <b-form-input
                    id="cnae"
                    v-model="enterpriseData.main_cnae"
                    v-mask="'##.##-#-##'"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="enterpriseData.isLegalPerson"
            >
              <b-form-group label="Inscrição Estadual">
                <b-input-group>
                  <b-form-input
                    id="inscricao-estadual"
                    v-model="enterpriseData.state_registration"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="enterpriseData.isLegalPerson"
            >
              <b-form-group label="Inscrição Municipal">
                <b-input-group>
                  <b-form-input
                    id="inscricao-municipal"
                    v-model="enterpriseData.municipal_registration"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="enterpriseData.isLegalPerson">
            <b-col
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="setor"
                rules="required"
              >
                <b-form-group label="Setor">
                  <b-form-select
                    id="setor"
                    v-model="enterpriseData.sector.id"
                    :options="sectorOptions"
                    :disabled="!edit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="empresa"
                rules="required"
              >
                <b-form-group label="Empresa Vinculada">
                  <b-input-group>
                    <b-form-select
                      id="empresa-vinculada"
                      v-model="enterpriseData.company.id"
                      :options="companiesOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <h6>Endereço da empresa</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col
              md="2"
            >
              <validation-observer ref="cep">
                <validation-provider
                  v-slot="{ errors }"
                  name="CEP"
                  rules="max:9|min:8"
                >
                  <b-form-group label="CEP">
                    <b-input-group>
                      <b-form-input
                        id="cep"
                        v-model="enterpriseData.zip_code"
                        :disabled="!edit"
                        type="text"
                        @blur="getAddress(enterpriseData.zip_code)"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </validation-observer>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group label="Cidade">
                <b-input-group>
                  <b-form-input
                    id="cidade"
                    v-model="enterpriseData.city"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group label="UF">
                <b-input-group>
                  <b-form-select
                    id="uf"
                    v-model="enterpriseData.state"
                    :options="ufOptions"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group label="Bairro">
                <b-input-group>
                  <b-form-input
                    id="bairro"
                    v-model="enterpriseData.neighborhood"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group label="Endereço">
                <b-input-group>
                  <b-form-input
                    id="endereco"
                    v-model="enterpriseData.address"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número / Complemento">
                <b-input-group>
                  <b-form-input
                    id="numero"
                    v-model="enterpriseData.address_number"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <h6>Dados do responsável</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col
              md="3"
            >
              <validation-provider
                v-slot="{ errors }"
                name="nome responsável"
                rules="alpha_spaces|max:50"
              >
                <b-form-group label="Nome">
                  <b-input-group>
                    <b-form-input
                      id="nome-responsavel"
                      v-model="contact.name"
                      :disabled="!edit"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
            >
              <validation-provider
                v-slot="{ errors }"
                name="e-mail"
                rules="email|max:50"
              >
                <b-form-group label="E-mail">
                  <b-input-group>
                    <b-form-input
                      id="email-responsavel"
                      v-model="contact.email"
                      :disabled="!edit"
                      type="email"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group label="Departamento">
                <b-input-group>
                  <b-form-input
                    id="departamento-responsavel"
                    v-model="contact.department"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group label="Cargo">
                <b-input-group>
                  <b-form-input
                    id="carg-responsavel"
                    v-model="contact.job_position"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group label="Data de nascimento">
                <b-input-group>
                  <b-form-input
                    id="data-responsavel"
                    v-model="contact.birth_date"
                    :disabled="!edit"
                    type="date"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="telefone"
                rules="phone"
              >
                <b-form-group label="Telefone">
                  <b-input-group>
                    <b-form-input
                      id="telefone-responsavel"
                      v-model="contact.telephone[0].telephone_number"
                      v-mask="maskTelephone(contact.telephone[0].telephone_number)"
                      :disabled="!edit"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group label="Marcador">
                <b-form-select
                  id="marcador"
                  v-model="contact.telephone[0].telephone_type"
                  :options="optionsMarcador"
                  :disabled="!edit"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="observações"
                rules="max:180"
              >
                <b-form-group label="Observações">
                  <b-form-textarea
                    id="textarea"
                    v-model="contact.note"
                    rows="2"
                    max-rows="4"
                    :disabled="!edit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <b-spinner
      v-else
      variant="primary"
      class="general-data-spinner"
    />
  </div>
</template>

<script>
import {
  BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BFormTextarea, BButton, BSpinner, BFormSelect, BFormRadioGroup,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import enterprises from '@/services/enterprises'
import { required } from '@/@core/utils/validations/validations'
import contacts from '@/services/contacts'
import { maskPhone } from '@/@core/mixins/ui/forms'

export default {
  components: {
    BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BFormTextarea, BButton, VSIcons, BSpinner, BFormSelect, BFormRadioGroup,
  },
  mixins: [
    maskPhone,
  ],
  data() {
    return {
      required,
      createEnterprise: false,
      createContact: false,
      optionsLegalPerson: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
      contact: {
        id: null,
        project_id: 0,
        name: '',
        email: '',
        job_position: '',
        department: '',
        birth_date: '',
        note: '',
        telephone: [
          {
            telephone_number: '',
            telephone_type: '',
            whatsapp_icon: false,
            principal_icon: true,
          },
        ],
      },
      enterpriseData: {
        id: null,
        isLegalPerson: true,
        cpf: '',
        document_issuer: '',
        identification_document: '',
        full_name: '',
        fantasy_name: '',
        project_name: '',
        cnpj: '',
        main_cnae: '',
        state_registration: '',
        municipal_registration: '',
        zip_code: '',
        address: '',
        address_number: '',
        neighborhood: '',
        city: '',
        state: '',
        sector: { name: '' },
        company: { nome_fantasia: '' },
      },
    }
  },
  computed: {
    ...mapGetters({
      edit: 'app/getItemTableEdit',
      apiInCall: 'app/getApiInCall',
      sectorOptions: 'sector/getSectorsOptions',
      companiesOptions: 'company/getCompaniesOptions',
      ufOptions: 'app/getUfOptions',
      optionsMarcador: 'contacts/getOptionsMarcador',
    }),
    subTitlePersonLegal() {
      return this.enterpriseData.isLegalPerson ? this.enterpriseData.fantasy_name : this.enterpriseData.full_name
    },
  },
  created() {
    if (this.verifyRouteAddNewItem()) {
      this.createEnterprise = true
      this.createContact = true
      this.setSubtitle('')
    } else {
      this.fetchEnterprise(this.$route.params.id).then(() => { this.createContact = !this.contact.name.length > 0 })
    }
    this.fetchSectors()
    this.fetchCompanies()
  },
  methods: {
    ...mapMutations({ setEdit: 'app/SET_ITEM_TABLE_EDIT', setSubtitle: 'app/SET_SUBTITLE', setEnterpriseData: 'enterprise/SET_ENTERPRISE_DATA' }),
    ...mapActions({
      fetchSectors: 'sector/fetchSectors',
      fetchCompanies: 'company/fetchCompanies',
      fetchContacts: 'contacts/fetchContacts',
    }),
    fetchEnterprise(id) {
      return new Promise(resolve => {
        enterprises.getEnterpriseById(id).then(res => {
          this.setEnterpriseData(res.data)
          this.enterpriseData = res.data
          this.setSubtitle(this.subTitlePersonLegal)
          contacts.getContactsByRelationAndId({ relation: 'Project', id: this.$route.params.id }).then(resContact => {
            this.contact.project_id = Number(this.$route.params.id)
            if (resContact.data.filter(arr => arr.isActive).length > 0) {
              // eslint-disable-next-line prefer-destructuring
              this.contact = resContact.data.filter(filterContact => filterContact.isActive && (filterContact.telephone.filter(arrTel => arrTel.principal_icon).length > 0))[0]
            }
            resolve()
          })
        })
      })
    },
    getDataCompanyByCnpj(cnpj) {
      this.getCompanyReceitaByCnpj(cnpj).then(res => {
        this.enterpriseData.fantasy_name = res.fantasia
        this.enterpriseData.project_name = res.nome
        this.enterpriseData.main_cnae = res.atividade_principal[0].code

        const cep = res.cep.replace(/\D/gim, '')
        this.enterpriseData.zip_code = cep
        this.getAddress(res.cep)
      })
    },
    saveChanges(form) {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.createEnterprise) {
            enterprises.createEnterprise(form).then(res => {
              this.setEdit(false)
              this.toast({
                title: 'Criado!',
                text: 'Empreendimento criado com sucesso!',
                variant: 'success',
              })
              this.enterpriseData.id = res.data.id
              this.createEnterprise = false
              this.setEnterpriseData(res.data)
              this.saveContact(res.data.id).then(() => { this.createContact = false })
              this.$router.push(`/empreendimento/${res.data.id}/detalhes`)
            })
          } else {
            enterprises.editEnterprise(form).then(res => {
              this.setEdit(false)
              this.toast({
                title: 'Atualizado!',
                text: 'Empreendimento atualizado com sucesso!',
                variant: 'success',
              })
              if (this.createContact) {
                this.saveContact(res.data.id).then(() => { this.createContact = false })
              } else {
                this.editContact()
              }
            })
          }
          this.setSubtitle(this.subTitlePersonLegal)
        }
      })
    },

    saveContact(id) {
      this.contact.project_id = id
      return new Promise((resolve, reject) => {
        if (this.contact.telephone[0].telephone_type === 'WhatsApp') this.contact.telephone[0].whatsapp_icon = true
        if (this.contact.name.length > 0) {
          contacts.createContact(this.contact).then(resContact => {
            this.contact.id = resContact.data.id
            this.fetchContacts()
            if (this.createContact) {
              this.contact.telephone[0].id = resContact.data.telephone.length > 0 ? resContact.data.telephone[0].id : null
            }
            resolve(resContact)
          }).catch(err => reject(err))
        }
      })
    },

    editContact() {
      contacts.editContact(this.contact).then(() => {
        this.fetchContacts()
      })
    },

    cancel() {
      this.setEdit(false)
      if (this.createEnterprise) {
        this.$router.push({ name: 'enterprise' })
      } else {
        this.fetchEnterprise(this.$route.params.id)
      }
    },

    getAddress(cep) {
      this.$refs.cep.validate().then(success => {
        if (success) {
          const address = this.getAdressByCep(cep)
          this.enterpriseData.address = address.logradouro
          this.enterpriseData.neighborhood = address.bairro
          this.enterpriseData.state = address.uf
          this.enterpriseData.city = address.localidade
          this.enterpriseData.address_number = address.complemento
        }
      })
    },
  },
}
</script>
