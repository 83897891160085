<template>
  <CompanyDetails />
</template>

<script>
import CompanyDetails from '@/modules/company/company-details/CompanyDetails.vue'

export default {
  components: { CompanyDetails },
}
</script>
