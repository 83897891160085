<template>
  <div class="enterprise-page">
    <div
      v-if="$can('create', 'licensing')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="$router.push('/licenciamento/adicionar/detalhes')"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="enterprise-table"
      class="details-table mt-4"
      :items="items.filter(arr => arr.project && arr.project.id === Number(this.$route.params.id))"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @row-selected="detailsProcess"
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'licensing')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/licensing/general')"
        />
      </template>
      <template
        v-if="$can('delete', 'licensing')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/licensing/general' })"
        />
      </template>
      <template #cell(licensingAgency)="row">
        {{ row.item.licensingAgency.acronyms }}
      </template>
      <template #cell(licenseType)="row">
        {{ row.item.licenseType.acronyms }}
      </template>
      <template #cell(documentos)="row">
        <div class="j-c-center">
          {{ row.item.docsActivity ? row.item.docsActivity.filter(arr => arr.isActive).length : '0' }}
          <VSIcons
            class="ml-1"
            name="FileFillGradient"
          />
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="enterprise-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal name="UploadFile" />
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BTable, BButton, BFormCheckbox, BSpinner, BPagination,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    VSModal, BInputGroup, BInputGroupPrepend, BFormInput, BTable, BButton, VSIcons, BFormCheckbox, BSpinner, BPagination,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Código',
          key: 'licensing_code',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Nome',
          key: 'licensing_name',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Órgão Licenciador',
          key: 'licensingAgency',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Tipo de Licença',
          key: 'licenseType',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Documentos',
          key: 'documentos',
          sortable: true,
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall' }),
  },
  methods: {
    detailsProcess(row) {
      this.$router.push(`/licenciamento/${row[0].id}/detalhes`)
    },
  },
}
</script>
