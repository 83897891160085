<template>
  <EnterpriseDetails />
</template>

<script>
import EnterpriseDetails from '@/modules/enterprise/enterprise-details/EnterpriseDetails.vue'

export default {
  components: { EnterpriseDetails },
}
</script>
